import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { Thanks } from '../../layout/Thanks';

import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

import { AccordionGeneral } from '../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../partials/AccordionBenefits';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../utils/seo/Index';

import { AboutValuationText } from '../../partials/AboutValuationText';
import { PageType } from '../../../__types__/generated/globalTypes';
import { useValuationContext } from '../../../utils/hooks/useValuationContext';
import { ValuationForm } from '../../partials/forms/Valuation';

const ValuationGeneral: React.FC = () => {
  const { posted, init } = useValuationContext();

  React.useEffect(() => {
    init({
      pageType: PageType.GENERELL
    });
  }, []);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  return (
    <>
      <Seo
        title="Salg av eiendom? - PrivatMegleren"
        description="Verdivurdering av eiendom, leilighet, hus,  estimert eiendomsverdi, E-takst. Kontakt PrivatMegleren. "
      />
      <Container className="padding-top" style={pageFadeIn}>
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              Velkommen til PrivatMegleren
            </Heading>

            <Paragraph center={true} className="form-text">
              Vi starter et hvert samarbeid med en nyttig samtale med deg om
              eiendommen du vurderer å selge. Slik kan vi på beste måte
              skreddersy en prosess, med et felles mål om et salg som overgår
              bransjens standard, og oppnå det beste resultatet.
            </Paragraph>
            <Paragraph center={true} className="form-text">
              Gjennom vår vurdering av eiendommen (verdivurdering) får du våre
              råd, innsyn i vår metodikk og et innblikk i alle våre nyskapende
              verktøy, som styrker hele salgsprosessen.
            </Paragraph>
            <Paragraph center={true} className="form-text">
              Ta kontakt, så hører du i fra oss.
            </Paragraph>

            {/*             <Heading tag="h1" center={true}>
              Skal du selge eiendom?
            </Heading>
            <Paragraph center={true} className="form-text">
              Lurer du på hva egen eiendom er verdt? Fyll inn skjema under og en
              eiendomsmegler kontakter deg.
            </Paragraph> */}
          </Section>
          <ValuationForm />
          <Section className="form-section">
            <AboutValuationText />
          </Section>
          <Section className="form-section">
            <AccordionBenefits />
          </Section>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
            <Paragraph center={true}>
              I mellomtiden kan du lese mer om salg av eiendom her.
            </Paragraph>
            <AccordionGeneral />
          </Section>
          <Section className="form-section" style={{ paddingTop: 0 }}>
            <AccordionBenefits />
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        opacity={0.3}
        url="https://cdn.reeltime.no/pm_assets/img/verdivurdering.jpg"
      />
    </>
  );
};

export default ValuationGeneral;

const FormWrapper = styled(animated.div)``;
